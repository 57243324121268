* {
  margin: 0;
  padding: 0;
}

.nav-container {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0.1px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #2c3531;
  color: #ffeb3b;
}

.nav-container a {
  margin-right: 3rem;
  margin-left: 1.5rem;
  color: #ffeb3b;
  font-size: 1.2rem;
  text-decoration: none;
}

.back-icon {
  margin-right: 3rem;
  margin-left: 1.5rem;
  font-size: 3rem;
  color: #ffeb3b;
}

.time-date {
  font-weight: bold;
}
