* {
  padding: 0;
  margin: 0;
}

.items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  color: #d79922;
}

body {
  background-color: #026670;
}
